/* eslint-disable react/prop-types */

import React from 'react';
import { useEBikesFeatureEnabled } from './use-feature';

export const EBikesFeature = ({ children }) => {
  const isEnabled = useEBikesFeatureEnabled();
  if (!isEnabled) return null;

  return <>{children}</>;
};
