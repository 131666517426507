import React from 'react';
import { Link } from 'gatsby';
//
import QuotesSlider from '../Sliders/QuotesSlider';
import './index.scss';
//
import IconPhone from '../../assets/svg/icon-phone.svg';
import IconLocation from '../../assets/svg/icon-location.svg';
import IconEnvelope from '../../assets/svg/icon-envelope.svg';
import IconFax from '../../assets/svg/icon-fax.svg';

const Footer = () => {
  return (
    <section className="footer">
      <div className="container">
        <div className="footer-intro">
          <div className="intro">
            <h4 className="n-t-m">About JP Superbikes</h4>
            <p className="text-gray">
              JP Superbikes Superstore offers a large selection of OEM and aftermarket parts for motorcylces, scooter,
              ATV/UTV.
            </p>
            <Link to="/page-about" className="text-red uppercase link-know-more">
              Know More
            </Link>
          </div>
          <div className="quotes-slider">
            <QuotesSlider />
          </div>
        </div>
        <hr />
        <div className="footer-main">
          <div>
            <h4>JP Superbikes, LLC Service Center</h4>
            <ul className="company-information">
              <li>
                <IconLocation />
                19800 Veterans Blvd, Unit C-9 <br /> Port Charlotte, FL 33954{' '}
              </li>
              <li>
                <IconPhone />
                (941) 883-6363
              </li>
              <li>
                <IconEnvelope />
                jpsuperbikes@comcast.net
              </li>
              <li>
                <IconFax />
                (941) 883-6433
              </li>
              <li>
                <a
                  className="link-external"
                  href="https://www.google.com/maps/place/JP+Superbikes+Llc/@27.0206212,-82.1262555,17z/data=!4m13!1m7!3m6!1s0x88dca961b7c60903:0xd47a659cb16693c3!2s19800+Veterans+Blvd,+Port+Charlotte,+FL+33954,+USA!3b1!8m2!3d27.0205969!4d-82.1252771!3m4!1s0x0:0x32c151d0ef471c6c!8m2!3d27.0203418!4d-82.1270319?hl=en"
                  target="_blank"
                >
                  Find Us On Map
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h4>JP Superbikes Superstore, LLC</h4>
            <ul className="company-information">
              <li>
                <IconLocation />
                19800 Veterans Blvd, Unit C-9 <br /> Port Charlotte, FL 33954{' '}
              </li>
              <li>
                <IconPhone />
                (941) 625-7198
              </li>
              <li>
                <IconEnvelope />
                jpsuperbikes@comcast.net
              </li>
              <li>
                <IconFax />
                (941) 883-6433
              </li>
              <li>
                <a
                  className="link-external"
                  href="https://www.google.com/maps/place/JP+Superbikes+Llc/@27.0206212,-82.1262555,17z/data=!4m13!1m7!3m6!1s0x88dca961b7c60903:0xd47a659cb16693c3!2s19800+Veterans+Blvd,+Port+Charlotte,+FL+33954,+USA!3b1!8m2!3d27.0205969!4d-82.1252771!3m4!1s0x0:0x32c151d0ef471c6c!8m2!3d27.0203418!4d-82.1270319?hl=en"
                  target="_blank"
                >
                  Find Us On Map
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h4>Usefull Tags</h4>
            <ul className="usefull-tags">
              <li>
                <Link className="link-tag" to="/">
                  Home
                </Link>
              </li>
              <li>
                <Link className="link-tag" to="/services">
                  Services
                </Link>
              </li>
              <li>
                <Link className="link-tag" to="/about">
                  Abous Us
                </Link>
              </li>
              <li>
                <Link className="link-tag" to="/page-contact">
                  Contact
                </Link>
              </li>
            </ul>
            {/* <h4>Subscribe Us</h4>
            <div className="footer-subscribe">
              <input name="emailSub" id="emailSub" type="text" placeholder="Email Address..." />
              <input type="submit" name="btnSubscr" value="GO" />
            </div> */}
          </div>
          <div>
            <h4>Working Hours</h4>
            <ul className="working-hours">
              <li>
                <p className="n-b-m">Monday-Friday: 9:00 to 5:00</p>
                <p className="n-b-m">Saturday: By Appointment</p>
                <p className="n-b-m">
                  Sunday: <span className="text-red">Closed</span>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-copyrights">
        <div className="container copyrights-grid">
          <p className="text-white">
            Copyrights <span className="text-red">© {new Date().getFullYear()}</span> All Rights Reserved by JP
            Superbikes
          </p>
          <ul className="list-inline">
            <li>
              <Link to="/" className="link-copyrights">
                Home
              </Link>
            </li>
            <li>
              <Link to="/services" className="link-copyrights">
                Services
              </Link>
            </li>
            <li>
              <Link to="/about" className="link-copyrights">
                Abous Us
              </Link>
            </li>
            <li>
              <Link to="/contact" className="link-copyrights">
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Footer;
