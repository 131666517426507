import React from 'react';
import Img from 'gatsby-image';
import { useStaticQuery, graphql, Link } from 'gatsby';
//
import './index.scss';

import IconLocation from '../../assets/svg/icon-location.svg';
import IconClock from '../../assets/svg/icon-clock.svg';
import IconPhone from '../../assets/svg/icon-phone.svg';
//

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logojp2.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <section className="header">
      <div className="container">
        <div className="header-grid">
          <Link to="/">
            <Img
              className="logo-wrapper"
              fixed={{ ...data.file.childImageSharp.fixed, height: 93 }}
              alt="A corgi smiling happily"
            />
          </Link>
          <div className="cell">
            <IconLocation />
            <p>
              19800 Veterans Blvd, Unit C9. <br /> Port Charlotte, FL 33954
            </p>
          </div>
          <div className="cell">
            <IconClock />
            <p>
              Mon-Fri 9:00 to 5:00
              <br />
              Sat: By Appointment
              <br />
              <span className="Sun-Mon">
                Sun: Closed<span></span>
              </span>
            </p>
          </div>
          <div className="cell">
            <IconPhone />
            <p>
              {' '}
              (941) 625-7198
              <br />
              (941) 883-6363
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;
