import React, { useState } from 'react';
import { Link } from 'gatsby';
//
import IconFacebook from '../../assets/svg/icon-facebook.svg';
import IconHamburger from '../../assets/svg/icon-hamburger.svg';
import { EBikesFeature } from '../../features/e-bikes';

import './index.scss';

const Navigation = () => {
  const [isVisible, setIsVisible] = useState(false);

  const clickHandler = () => {
    setIsVisible(!isVisible);
  };

  const visibilityClass = isVisible === true ? 'show slide-down' : '';

  return (
    <div className="navigation-bar">
      <div className="container">
        <div className="navigation-wrapper">
          <div className="navbar-hamburger-wrapper">
            <h1 className="hamburger-info n-t-m n-b-m">Certified used motorcycles, scooters, ATVs/UTVs for sale</h1>
            <button className="btn" type="button" aria-expanded="false" aria-controls="navbar" onClick={clickHandler}>
              <IconHamburger />
            </button>
          </div>
          <ul className={`nav-bar ${visibilityClass}`}>
            <li>
              <Link className="link-nav" to="/">
                HOME
              </Link>
            </li>
            <li>
              <Link className="link-nav" to="/motorcycles">
                Motorcycles
              </Link>
            </li>
            <EBikesFeature>
              <li>
                <Link className="link-nav" to="/e-bikes">
                  E-Bikes
                </Link>
              </li>
            </EBikesFeature>
            <li>
              <Link className="link-nav" to="/about">
                About Us
              </Link>
            </li>
            <li>
              <Link className="link-nav" to="/services">
                Services
              </Link>
            </li>

            <li>
              <Link className="link-nav" to="/gallery">
                Gallery
              </Link>
            </li>
            <li>
              <Link className="link-nav" to="/contact">
                Contact
              </Link>
            </li>
          </ul>
          <ul className={`social-header ${visibilityClass}`}>
            <li>
              <Link className="link-nav" to="https://www.facebook.com/Jpsuperbikes-LLC-169922166402234/">
                <IconFacebook />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Navigation;
