import React from 'react';
import Slider from 'react-slick';
//
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './index.scss';
//
import IconQuote from '../../../assets/svg/icon-quote.svg';

const QuotesSlider = () => {
  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    speed: 1500,
    autoplaySpeed: 3500,
  };

  return (
    <Slider {...settings}>
      <div className="single-slide-content">
        <IconQuote />
        <div>
          <p>Our Quotes - </p>
          <p>You are only one bike ride away from a good mood!</p>
        </div>
      </div>
      <div>
        <div className="single-slide-content">
          <IconQuote />
          <div>
            <p>Our Quotes - </p>
            <p>Straights are for fast bikes, curves are for fast riders!</p>
          </div>
        </div>
      </div>
      <div>
        <div className="single-slide-content">
          <IconQuote />
          <div>
            <p>Our Quotes - </p>
            <p>
              Sometimes you find yourself in the middles of nowhere and sometimes in the middles of nowhere you find
              yourself!
            </p>
          </div>
        </div>
      </div>
      <div>
        <div className="single-slide-content">
          <IconQuote />
          <div>
            <p>Our Quotes - </p>
            <p>You are only one bike ride away from a good mood!</p>
          </div>
        </div>
      </div>
    </Slider>
  );
};

export default QuotesSlider;
